<template>
  <div class="w-60 bg-base-200 h-full">
    <div class="px-5 mb-8 pt-8">
      <img class="max-w-[190px]" :src="logoUrl" alt="logo" />
    </div>
    <!-- Sidebar content here -->
    <div class="flex flex-col justify-between px-5 py-4">
      <div class="flex flex-col space-y-2">
        <!-- <NuxtLink
          v-if="session.tenant?.type === TenantType.Distributor"
          :to="{ name: 'tenant-policies-new' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgClaimsIcon></ImgClaimsIcon>

          New policy
        </NuxtLink> -->

        <!-- <NuxtLink
          v-if="isStaging && getTenantName('Attica Bank')"
          :to="{ name: 'tenant-new_applications' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgPolicyIcon></ImgPolicyIcon>
          Applications
        </NuxtLink> -->

        <div v-if="getEnvProfile" class="space-y-2">
          <NuxtLink
            :to="{ name: 'tenant-policies' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgPolicies></ImgPolicies>
            Policies
          </NuxtLink>

          <NuxtLink
            :to="{ name: 'tenant-insurlets' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgInsurletIcon></ImgInsurletIcon>

            Insurlets
          </NuxtLink>

          <NuxtLink
            :to="{ name: 'tenant-distributors' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgDistributorIcon></ImgDistributorIcon>
            Distributors
          </NuxtLink>

          <NuxtLink
            :to="{ name: 'tenant-insurers' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgInsurersIcon></ImgInsurersIcon>
            Insurers
          </NuxtLink>

          <NuxtLink
            :to="{ name: 'tenant-developers' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgDeveloperIcon></ImgDeveloperIcon>
            Developer Hub
          </NuxtLink>
        </div>

        <div v-else-if="isForRetailersDemo" class="space-y-2">
          <NuxtLink
            :to="{ name: 'tenant-retailers-applications' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgApplication></ImgApplication>
            {{ content.applications }}
          </NuxtLink>

          <NuxtLink
            :to="{ name: 'tenant-policies' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgPolicies></ImgPolicies>
            {{ content.policies }}
          </NuxtLink>
        </div>

        <div v-else class="space-y-2">
          <NuxtLink
            v-if="isForΒancasureDemo || isForInternalUsers"
            :to="{ name: 'tenant-dashboard' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgDashboardIcon></ImgDashboardIcon>
            Dashboard
          </NuxtLink>

          <NuxtLink
            :to="{ name: 'tenant-applications' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgApplication></ImgApplication>
            Applications
          </NuxtLink>

          <NuxtLink
            :to="{ name: 'tenant-policies' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgPolicies></ImgPolicies>
            Policies
          </NuxtLink>
          <NuxtLink
            v-if="isDevelop"
            :to="{ name: 'tenant-claims' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgClaims></ImgClaims>
            Claims
          </NuxtLink>
          <NuxtLink
            v-if="isDevelop"
            :to="{ name: 'tenant-billing' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgWallet></ImgWallet>
            Revenue Sharing
          </NuxtLink>
          <hr />
          <NuxtLink
            v-if="!isForΒancasureDemo"
            :to="{ name: 'tenant-insurlets' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgInsurletIcon></ImgInsurletIcon>

            Insurlets
          </NuxtLink>

          <NuxtLink
            v-if="!isForΒancasureDemo"
            :to="{ name: 'tenant-distributors' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgDistributorIcon></ImgDistributorIcon>
            Distributors
          </NuxtLink>

          <NuxtLink
            v-if="!isForΒancasureDemo"
            :to="{ name: 'tenant-insurers' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgInsurersIcon></ImgInsurersIcon>
            Insurers
          </NuxtLink>

          <NuxtLink
            v-if="!isForΒancasureDemo"
            :to="{ name: 'tenant-developers' }"
            @click="menuClicked"
            class="menu-item group"
          >
            <ImgDeveloperIcon></ImgDeveloperIcon>
            Developer Hub
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const content = ref();
  const env = getCurrentEnvironment();
  const getEnvProfile = computed(() => {
    return useRuntimeConfig().public.wbIsProduction;
  });

  const isDevelop = computed(() => {
    return developEnvironments.includes(env as Develop);
  });

  const bancasureTenants = [
    "285741dc-70e7-4185-bd38-9180114f6097",
    "faeb7471-d5ce-4c04-a0d3-acf65f782b77",
    "fb43f618-a132-4a1b-8912-57ddf791b891",
    "d7fc2ef0-ff5f-4f41-8a22-21896c063540",
    "38734770-7a2c-42e1-b287-25594df0e063",
    "fcae7fe6-96ff-4efb-a8a8-36620f38ea51",
    "94231ab3-7456-4445-a241-2424707e6805",
    "02ba0de3-1835-421a-b525-55a71a194964"
  ];

  const retailersTenants = [
    "98cc9a45-a21d-46b5-b6c3-ff62c0955174",
    "4fa59fc5-8c63-417f-9ba7-d2a90a1e2437",
    "98285810-214e-4c8a-bd53-f9a432cde029",
    "64e7d1dd-800e-4f52-b155-c89f8700f827"
  ];

  const isForΒancasureDemo = computed(() => {
    return bancasureTenants.includes(useRoute().params.tenant as string);
  });

  const isForInternalUsers = computed(() => {    
    const getTenant = useRoute().params.tenant as string;
    return getTenant === '11111111-1111-1111-1111-111111111111';
  });

  const isForRetailersDemo = computed(() => {
    return retailersTenants.includes(useRoute().params.tenant as string);
  });

  if (isForRetailersDemo.value)
    content.value = await queryContent("el/side_menu").findOne();

  const emit = defineEmits<{
    "menu-clicked": [name: string];
  }>();

  const menuClicked = () => {
    let name = "unknown";
    if (useRoute().name) {
      name = useRoute().name!.toString();
    }
    emit("menu-clicked", name);
  };

  const logoUrl = ref<string>(
    useTenantAssetLogoUrl(useRoute().params.tenant as string)
  );
</script>

<style scoped>
  .menu-item {
    @apply flex gap-1 items-center rounded-md px-1 py-1 hover:text-primary-content  hover:bg-[color-mix(in_oklab,oklch(var(--p)),black_7%)] transition-all duration-200;
  }

  .router-link-active.router-link-exact-active {
    @apply bg-primary text-primary-content;
  }

  .router-link-active.router-link-exact-active svg {
    @apply text-primary-content;
  }
</style>
